<template>
  <div>
    <div class="toppicd">
      <img src="../../../Newimg/fuwu.jpg" alt="" />
    </div>
    <div
      style="width: 100%; height: auto; background-color: #f7f7f7"
      id="server"
    >
      <div class="bg">
        <div class="w" style="width:1200px;">
          <div class="enter">
            <mytitle>顾问服务 </mytitle>
            <div class="severList">
              <div class="item" v-for="(item, index) in list" :key="index" @click="linktodetails(item.url)" :style="index%4 ==3?'margin-right:0':''">
                <img class="icon" :src="url + item.pictureUrl" alt="" />
                <div class="data">
                  <div class="title">{{item.title}}</div>
                  <div class="time">{{item.uploadTime}}</div>
                </div>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                class="fy"
                small
                @size-change="handleSizeChange"
                :page-size="pagesize"
                :page-sizes="[12, 16, 20]"
                layout="total, sizes, prev, pager, next, jumper"
                @current-change="current_change"
                :total="total"
                background
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="width: 100%; height: auto; background-color: #ffffff"
      id="question"
    >
      <div class="bg">
        <div class="w" style="width:1200px;">
          <div class="enter">
            <mytitle>常见问题</mytitle>
            <div class="questions-box">
              <div
                class="questions-item"
                v-for="(item, index) in question"
                :key="index"
              >
                <div class="questions">
                  <span class="questionsactive">Q</span>
                  {{ item.problem }}
                </div>
                <div class="answer">
                  <span class="questionsactive">A</span>
                  <div style="flex: 1" v-html="item.answer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topic from "@/components/Topics.vue";
import mytitle from "@/components/title.vue";
import { problemByType,listCounseling } from "../../API/homepage/problem.js";

export default {
  components: {
    Topic,
    mytitle,
  },
  data() {
    return {
      //入驻流程数据
      imgsLists: [
        {
          src: require("../../assets/image/img08.png"),
          title: "修改润色",
          cont:
            "双语种编辑同时在线，为追求高质量期刊投稿的作者打造的论文润色，语言检查外全面提升稿件的逻辑及呈现，让您的研究掷地有声。",
        },

        {
          src: require("../../assets/image/img06.png"),
          title: "快速精准",
          cont:
            "我们的编辑将根据您所投期刊的要求、职称评审要求，为您的文稿进行不限于结构顺序、段落、参考文献的格式修改及校对。",
        },
      ],
      question: [],
      pageNum: 1,
      limit: 10,
      url: "", // 图片地址

      list:[],
      total: 0, //默认数据总数
      pagesize: 12, //每页的数据条数
      currentPage: 1, //默认开始页面
    };
  },
  mounted() {
    this.url = this.URL;
  },
  created() {
    if (this.$route.query.id) {
      this.now = this.$route.query.id;
      setTimeout(() => {
        var element = document.getElementById(this.$route.query.id);
        console.log(element, this.$route.query.id);
        if (element) {
          element.scrollIntoView();
        }
      }, 200);
    }
    this.getQuestion();
    this.showmagzine();
  },
  methods: {
    showmagzine () {
      listCounseling(this.currentPage, this.pagesize).then(res => {
        this.list = res.data.data
        this.total = res.data.total
      })
    },
    //跳转详情
    linktodetails (url) {
      console.log(url);
      window.open(url)
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.showmagzine()
    }, 
    handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.showmagzine()
    },
    async getQuestion() {
      // 1.智能投稿常见问题2.顾问服务常见问题3.平台入驻常见问题4.关于我们常见问题
      problemByType(this.pageNum, this.limit, 2).then((res) => {
        this.question = res.data.data;
      });
    },
  },
  watch: {
    $route(to, from) {
      console.log("监听到路由变动了", to.path);
      if (this.$route.query.id) {
        this.now = this.$route.query.id;
        setTimeout(() => {
          var element = document.getElementById(this.$route.query.id);
          console.log(element, this.$route.query.id);
          if (element) {
            element.scrollIntoView();
          }
        }, 200);
      }
    },
  },
};
</script>

<style>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.severList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}
.severList .item {
  width: 279px;
  margin-right: 28px;
  background: #fff;
  margin-bottom: 23px;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s;
}
.severList .item:hover {
  box-shadow: 0 0 10px 10px rgba(100,100,100,0.1);
}
.severList .item .icon {
  width: 100%;
  height: 156px;
}
.severList .item .data {
  box-sizing: border-box;
  padding: 10px 16px;
  padding-bottom: 16px;
}
.severList .item .data .title {
  font-size: 16px;
  color: #222;
  font-weight: bold;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: 22px;
  height: 44px;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.severList .item .data .time {
  font-size: 12px;
  color: #757575;
  margin-top: 20px;
}

.questions-box {
  width: 100%;
  background: #f7f7f7;
  padding: 0 26px;
  padding-bottom: 80px;
  margin-top: 54px;
  border-radius: 14px;
}
.questions-box .questions-item {
  height: 180px;
  box-sizing: border-box;
  padding-top: 50px;
  border-bottom: 1px solid #e5e5e5;
}
.questions-box .questions-item .questionsactive {
  width: 20px;
  height: 20px;
  color: #fff;
  background: #1082ff;
  display: inline-flex;
  font-size: 12px;
  align-items: center;
  margin-right: 16px;
  justify-content: center;
}
.questions {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #1082ff;
}
.answer {
  display: flex;
  margin-top: 26px;
  font-size: 14px;
  line-height: 20px;
  color: #606060;
}
.enter {
  padding: 0px 0 36px 0;
}
.liuchengtu {
  width: 100%;
  height: auto;

  background-color: #ffffff;
}
.enter_cont {
  display: flex;
  justify-content: space-between;
}
.conent {
  width: auto;
  height: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.btns {
  position: relative;
  top: 10px;
  background-color: red;
}
.issue {
  padding: 50px 0 0;
}
.issue .list {
  padding: 30px;
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 20px;
}
.issue .list div {
  display: flex;
}

.issue .list div img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.issue .list div h3 {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 22px;
}
.issue .list div p {
  width: 1190px;
  height: 52px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
</style>